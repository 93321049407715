import React from "react";
import { Bar } from "react-chartjs-2";
import { Typography, Box, Paper, Grid, LinearProgress } from "@mui/material";
import { styled } from "@mui/system";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: 24,
  boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
  background: "linear-gradient(145deg, #ffffff, #f8f9fa)",
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 15px 35px rgba(0, 0, 0, 0.12)",
  },
}));

const ScoreTypography = styled(Typography)(({ score }) => ({
  fontWeight: 800,
  color: score < 3 ? '#FF6B6B' : score < 7 ? '#FFD93D' : '#6BCB77',
  fontFamily: "'Poppins', sans-serif",
}));

const AnalysisChart = ({ score = 0, title = "Performance Analysis" }) => {
  const getColor = (score) => {
    if (score < 3) return "rgba(255, 107, 107, 0.8)";
    if (score < 7) return "rgba(255, 217, 61, 0.8)";
    return "rgba(107, 203, 119, 0.8)";
  };

  const data = {
    labels: ["Score"],
    datasets: [
      {
        label: "Score out of 10",
        data: [score],
        backgroundColor: getColor(score),
        borderColor: "rgba(255, 255, 255, 0.6)",
        borderWidth: 2,
        borderRadius: 20,
        borderSkipped: false,
      },
    ],
  };

  const options = {
    indexAxis: "y",
    scales: {
      x: {
        beginAtZero: true,
        max: 10,
        ticks: {
          stepSize: 1,
          color: "rgba(0, 0, 0, 0.6)",
          font: {
            weight: 600,
            family: "'Roboto', sans-serif",
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    animation: {
      duration: 2000,
      easing: "easeOutQuart",
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <StyledPaper>
      <Typography variant="h4" align="center" gutterBottom fontWeight="800" color="primary" fontFamily="'Poppins', sans-serif">
        {title}
      </Typography>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={4}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <ScoreTypography variant="h2" score={score}>
              {score}
            </ScoreTypography>
            <Typography variant="subtitle1" color="text.secondary" fontFamily="'Roboto', sans-serif">
              out of 10
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box sx={{ height: "100px", mb: 2 }}>
            <Bar data={data} options={options} />
          </Box>
          <LinearProgress 
            variant="determinate" 
            value={score * 10} 
            sx={{ 
              height: 12, 
              borderRadius: 6,
              backgroundColor: 'rgba(0,0,0,0.08)',
              '& .MuiLinearProgress-bar': {
                backgroundColor: getColor(score),
                borderRadius: 6,
              }
            }} 
          />
        </Grid>
      </Grid>
      <Typography variant="body2" align="center" mt={2} color="text.secondary" fontFamily="'Roboto', sans-serif">
        {score < 3 ? "Needs Improvement" : score < 7 ? "Good Performance" : "Excellent Performance"}
      </Typography>
    </StyledPaper>
  );
};

export default AnalysisChart;
