import React from "react";
import { Typography, Box, Paper, Grid, Chip, Fade, Grow, Button } from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import AnalysisChart from "./AnalysisChart";
import { motion } from "framer-motion";

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  padding: theme.spacing(4),
  borderRadius: 24,
  backgroundColor: "#ffffff",
  boxShadow: "0 10px 30px rgba(0, 0, 0, 0.08)",
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 15px 35px rgba(0, 0, 0, 0.12)",
  },
}));

const QuestionTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
  fontFamily: "'Poppins', sans-serif",
}));

const AnswerTypography = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  padding: theme.spacing(2),
  borderRadius: 16,
  marginBottom: theme.spacing(2),
  fontFamily: "'Roboto', sans-serif",
  lineHeight: 1.6,
}));

const FeedbackTypography = styled(Typography)(({ theme }) => ({
  fontStyle: "italic",
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(2),
  fontFamily: "'Roboto', sans-serif",
}));

const AnalysisSummary = ({ analyses = [] }) => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleBackToDashboard = () => {
    navigate("/dashboard"); // Navigate to the dashboard route
  };

  if (!analyses.length) {
    return (
      <Typography variant="h6" color="text.secondary" textAlign="center" fontFamily="'Poppins', sans-serif">
        No analysis data available.
      </Typography>
    );
  }

  return (
    <Box textAlign="center" p={4} sx={{ background: "linear-gradient(145deg, #f3f4f6, #ffffff)" }}>
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Typography variant="h3" gutterBottom fontWeight="800" color="primary" fontFamily="'Poppins', sans-serif">
          Your Analysis Summary
        </Typography>
      </motion.div>
      {analyses.map((item, index) => (
        <Grow in={true} timeout={(index + 1) * 500} key={index}>
          <StyledPaper>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <QuestionTypography variant="h5">
                  Q: {item.question}
                </QuestionTypography>
                <AnswerTypography variant="body1">
                  A: {item.answer}
                </AnswerTypography>
                <FeedbackTypography variant="body1">
                  Feedback: {item.analysis?.feedback || "No feedback available"}
                </FeedbackTypography>
                <Box display="flex" justifyContent="center" flexWrap="wrap" gap={1}>
                  {item.analysis?.keywords?.map((keyword, idx) => (
                    <Fade in={true} timeout={(idx + 1) * 300} key={idx}>
                      <Chip 
                        label={keyword} 
                        color="primary" 
                        variant="outlined" 
                        sx={{ 
                          borderRadius: '16px', 
                          fontWeight: 600,
                          '&:hover': {
                            backgroundColor: 'primary.light',
                            color: 'primary.contrastText',
                          },
                        }} 
                      />
                    </Fade>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                  <AnalysisChart score={item.analysis?.score || 0} title="Score" />
                </Box>
              </Grid>
            </Grid>
          </StyledPaper>
        </Grow>
      ))}

      {/* Back to Dashboard Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleBackToDashboard}
        sx={{ mt: 4, fontWeight: 'bold', borderRadius: '12px' }}
      >
        Back to Dashboard
      </Button>
    </Box>
  );
};

export default AnalysisSummary;
