import React, { useEffect, useState } from 'react';
import { Box, Grid, Paper, Typography, CircularProgress, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Bar, Pie } from 'react-chartjs-2';
import 'chart.js/auto'; // Import the required chart.js components

const Dashboard = () => {
  const [analytics, setAnalytics] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Use navigate to redirect

  useEffect(() => {
    const fetchAnalytics = async () => {
      try {
        const userId = JSON.parse(localStorage.getItem("user"))?._id; // Retrieve userId from localStorage
        const response = await axios.get(`https://hirehack.io/api/interview/analytics/${userId}`);
        setAnalytics(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching analytics:', error);
        setLoading(false);
      }
    };

    fetchAnalytics();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!analytics) {
    return <Typography variant="h6" color="error" align="center">Error fetching analytics.</Typography>;
  }

  // Prepare data for charts
  const { totalInterviews, topicBreakdown, recentInterviews } = analytics;
  const topicLabels = Object.keys(topicBreakdown);
  const topicData = Object.values(topicBreakdown);

  const barChartData = {
    labels: topicLabels,
    datasets: [
      {
        label: 'Topics Covered',
        data: topicData,
        backgroundColor: ['#3b82f6', '#8b5cf6', '#ec4899', '#f59e0b', '#10b981'],
      },
    ],
  };

  const pieChartData = {
    labels: topicLabels,
    datasets: [
      {
        data: topicData,
        backgroundColor: ['#3b82f6', '#8b5cf6', '#ec4899', '#f59e0b', '#10b981'],
      },
    ],
  };

  return (
    <Box sx={{ padding: { xs: 2, sm: 4 }, backgroundColor: '#f9f9f9', minHeight: '100vh' }}>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center', mb: 4 }}>
        Dashboard Analytics
      </Typography>

      <Grid container spacing={4} justifyContent="center">
        {/* Start Practicing Button */}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" mb={4}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => navigate('/generate')}
              sx={{
                fontWeight: 'bold',
                padding: '8px 16px',
                borderRadius: '8px',
              }}
            >
              Start Practicing
            </Button>
          </Box>
        </Grid>

        {/* Total Interviews */}
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ padding: 2, textAlign: 'center', height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Total Interviews
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#3b82f6' }}>
              {totalInterviews}
            </Typography>
          </Paper>
        </Grid>

        {/* Bar Chart for Topics */}
        <Grid item xs={12} md={8}>
          <Paper sx={{ padding: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Topics Breakdown
            </Typography>
            <Box sx={{ height: 300 }}>
              <Bar data={barChartData} options={{ maintainAspectRatio: false }} />
            </Box>
          </Paper>
        </Grid>

        {/* Pie Chart */}
        <Grid item xs={12} sm={6}>
          <Paper sx={{ padding: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Topics Coverage (Pie)
            </Typography>
            <Box sx={{ height: 300 }}>
              <Pie data={pieChartData} options={{ maintainAspectRatio: false }} />
            </Box>
          </Paper>
        </Grid>

        {/* Recent Interviews */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Recent Interviews
            </Typography>
            {recentInterviews.length > 0 ? (
              recentInterviews.map((interview, index) => (
                <Box key={index} sx={{ marginBottom: 2 }}>
                  <Typography variant="subtitle1">
                    {interview.companyName} - {interview.topic}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {interview.interviewType} on {new Date(interview.date).toLocaleDateString()}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography variant="body2" color="textSecondary">No recent interviews.</Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
