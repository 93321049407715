// import React from "react";

// const Pricing = () => {
//   return (
//     <section className="relative z-10 overflow-hidden bg-white pb-12 pt-20 dark:bg-dark lg:pb-[90px] lg:pt-[120px]">
//       <div className="container mx-auto">
//         <div className="-mx-4 flex flex-wrap">
//           <div className="w-full px-4">
//             <div className="mx-auto mb-[60px] max-w-[510px] text-center">
//               <span className="mb-2 block text-lg font-semibold text-primary">
//                 Pricing Table
//               </span>
//               <h2 className="mb-3 text-3xl font-bold leading-[1.208] text-dark dark:text-white sm:text-4xl md:text-[40px]">
//                 Our Pricing Plans
//               </h2>
//               <p className="text-base text-body-color dark:text-dark-6">
//                 Choose the best plan that suits your needs and get started with
//                 your interview preparation journey.
//               </p>
//             </div>
//           </div>
//         </div>

//         <div className="-mx-4 flex flex-wrap justify-center">
//           <div className="-mx-4 flex flex-wrap">
//             <PricingCard
//               type="Basic"
//               price="₹500"
//               subscription="month"
//               description="Ideal for a short-term trial or one-off projects."
//               buttonText="Choose Basic"
//             >
//               <List>1 User</List>
//               <List>All UI components</List>
//               <List>1 Month access</List>
//               <List>Free updates</List>
//               <List>Use on 1 project</List>
//               <List>1 Month support</List>
//             </PricingCard>
//             <PricingCard
//               type="Standard"
//               price="₹1200"
//               subscription="3 months"
//               description="Best for a medium-term engagement with extended features."
//               buttonText="Choose Standard"
//               active
//             >
//               <List>5 Users</List>
//               <List>All UI components</List>
//               <List>3 Months access</List>
//               <List>Free updates</List>
//               <List>Use on 3 projects</List>
//               <List>3 Months support</List>
//             </PricingCard>
//             <PricingCard
//               type="Premium"
//               price="₹2000"
//               subscription="6 months"
//               description="Perfect for long-term use with full support and benefits."
//               buttonText="Choose Premium"
//             >
//               <List>Unlimited Users</List>
//               <List>All UI components</List>
//               <List>6 Months access</List>
//               <List>Free updates</List>
//               <List>Use on unlimited projects</List>
//               <List>6 Months support</List>
//             </PricingCard>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Pricing;

// const PricingCard = ({
//   children,
//   description,
//   price,
//   type,
//   subscription,
//   buttonText,
//   active,
// }) => {
//   return (
//     <div className="w-full px-4 md:w-1/2 lg:w-1/3">
//       <div className="relative z-10 mb-10 overflow-hidden rounded-[10px] border-2 border-stroke bg-white px-8 py-10 shadow-pricing dark:border-dark-3 dark:bg-dark-2 sm:p-12 lg:px-6 lg:py-10 xl:p-[50px]">
//         <span className="mb-3 block text-lg font-semibold text-primary">
//           {type}
//         </span>
//         <h2 className="mb-5 text-[42px] font-bold text-dark dark:text-white">
//           {price}
//           <span className="text-base font-medium text-body-color dark:text-dark-6">
//             / {subscription}
//           </span>
//         </h2>
//         <p className="mb-8 border-b border-stroke pb-8 text-base text-body-color dark:border-dark-3 dark:text-dark-6">
//           {description}
//         </p>
//         <div className="mb-9 flex flex-col gap-[14px]">{children}</div>
//         <a
//           href="/#"
//           className={` ${
//             active
//               ? "block w-full rounded-md border border-primary bg-primary p-3 text-center text-base font-medium text-white transition hover:bg-opacity-90"
//               : "block w-full rounded-md border border-stroke bg-transparent p-3 text-center text-base font-medium text-primary transition hover:border-primary hover:bg-primary hover:text-white dark:border-dark-3"
//           }`}
//         >
//           {buttonText}
//         </a>
//         <div>
//           <span className="absolute right-0 top-7 z-[-1]">
//             <svg
//               width={77}
//               height={172}
//               viewBox="0 0 77 172"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <circle cx={86} cy={86} r={86} fill="url(#paint0_linear)" />
//               <defs>
//                 <linearGradient
//                   id="paint0_linear"
//                   x1={86}
//                   y1={0}
//                   x2={86}
//                   y2={172}
//                   gradientUnits="userSpaceOnUse"
//                 >
//                   <stop stopColor="#3056D3" stopOpacity="0.09" />
//                   <stop offset={1} stopColor="#C4C4C4" stopOpacity={0} />
//                 </linearGradient>
//               </defs>
//             </svg>
//           </span>
//           <span className="absolute right-4 top-4 z-[-1]">
//             <svg
//               width={41}
//               height={89}
//               viewBox="0 0 41 89"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <circle
//                 cx="38.9138"
//                 cy="87.4849"
//                 r="1.42021"
//                 transform="rotate(180 38.9138 87.4849)"
//                 fill="#3056D3"
//               />
//               <circle
//                 cx="38.9138"
//                 cy="74.9871"
//                 r="1.42021"
//                 transform="rotate(180 38.9138 74.9871)"
//                 fill="#3056D3"
//               />
//               <circle
//                 cx="38.9138"
//                 cy="62.4892"
//                 r="1.42021"
//                 transform="rotate(180 38.9138 62.4892)"
//                 fill="#3056D3"
//               />
//               <circle
//                 cx="38.9138"
//                 cy="38.3457"
//                 r="1.42021"
//                 transform="rotate(180 38.9138 38.3457)"
//                 fill="#3056D3"
//               />
//               <circle
//                 cx="38.9138"
//                 cy="13.634"
//                 r="1.42021"
//                 transform="rotate(180 38.9138 13.634)"
//                 fill="#3056D3"
//               />
//               <circle
//                 cx="38.9138"
//                 cy="50.2754"
//                 r="1.42021"
//                 transform="rotate(180 38.9138 50.2754)"
//                 fill="#3056D3"
//               />
//               <circle
//                 cx="38.9138"
//                 cy="26.1319"
//                 r="1.42021"
//                 transform="rotate(180 38.9138 26.1319)"
//                 fill="#3056D3"
//               />
//               <circle
//                 cx="38.9138"
//                 cy="1.42021"
//                 r="1.42021"
//                 transform="rotate(180 38.9138 1.42021)"
//                 fill="#3056D3"
//               />
//               <circle
//                 cx="26.4157"
//                 cy="87.4849"
//                 r="1.42021"
//                 transform="rotate(180 26.4157 87.4849)"
//                 fill="#3056D3"
//               />
//               <circle
//                 cx="26.4157"
//                 cy="74.9871"
//                 r="1.42021"
//                 transform="rotate(180 26.4157 74.9871)"
//                 fill="#3056D3"
//               />
//               <circle
//                 cx="26.4157"
//                 cy="62.4892"
//                 r="1.42021"
//                 transform="rotate(180 26.4157 62.4892)"
//                 fill="#3056D3"
//               />
//               <circle
//                 cx="26.4157"
//                 cy="38.3457"
//                 r="1.42021"
//                 transform="rotate(180 26.4157 38.3457)"
//                 fill="#3056D3"
//               />
//               <circle
//                 cx="26.4157"
//                 cy="13.634"
//                 r="1.42021"
//                 transform="rotate(180 26.4157 13.634)"
//                 fill="#3056D3"
//               />
//               <circle
//                 cx="26.4157"
//                 cy="50.2754"
//                 r="1.42021"
//                 transform="rotate(180 26.4157 50.2754)"
//                 fill="#3056D3"
//               />
//               <circle
//                 cx="26.4157"
//                 cy="26.1319"
//                 r="1.42021"
//                 transform="rotate(180 26.4157 26.1319)"
//                 fill="#3056D3"
//               />
//               <circle
//                 cx="26.4157"
//                 cy="1.42021"
//                 r="1.42021"
//                 transform="rotate(180 26.4157 1.42021)"
//                 fill="#3056D3"
//               />
//             </svg>
//           </span>
//         </div>
//       </div>
//     </div>
//   );
// };

// const List = ({ children }) => (
//   <div className="relative flex items-center">
//     <svg
//       width="8"
//       height="8"
//       viewBox="0 0 8 8"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//       className="mr-2.5 flex-shrink-0"
//     >
//       <circle cx="4" cy="4" r="4" fill="#3056D3" />
//     </svg>
//     <span className="text-base text-body-color dark:text-dark-6">
//       {children}
//     </span>
//   </div>
// );
import React, { useState } from "react";

function PricingChart() {
  const [isAnnual, setIsAnnual] = useState(false); // Set the default to Monthly since all plans have a monthly pricing

  return (
    <>
      <div className="relative font-inter antialiased">
        <div className="relative min-h-screen flex flex-col justify-center bg-slate-50 overflow-hidden">
          <div className="w-full max-w-6xl mx-auto px-4 md:px-6 py-24">
            <div>
              {/* Toggle Switch */}
              {/* <div className="flex justify-center max-w-[14rem] m-auto mb-8 lg:mb-16">
                <div className="relative flex w-full p-1 bg-white dark:bg-slate-900 rounded-full">
                  <span
                    className="absolute inset-0 m-1 pointer-events-none"
                    aria-hidden="true"
                  >
                    <span
                      className={`absolute inset-0 w-1/2 bg-indigo-500 rounded-full shadow-sm shadow-indigo-950/10 transform transition-transform duration-150 ease-in-out ${
                        isAnnual ? "translate-x-full" : "translate-x-0"
                      }`}
                    ></span>
                  </span>
                  <button
                    className={`relative flex-1 text-sm font-medium h-8 rounded-full focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150 ease-in-out ${
                      isAnnual
                        ? "text-slate-500 dark:text-slate-400"
                        : "text-white"
                    }`}
                    onClick={() => setIsAnnual(false)}
                    aria-pressed={!isAnnual}
                  >
                    Monthly
                  </button>
                  <button
                    className={`relative flex-1 text-sm font-medium h-8 rounded-full focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150 ease-in-out ${
                      isAnnual
                        ? "text-white"
                        : "text-slate-500 dark:text-slate-400"
                    }`}
                    onClick={() => setIsAnnual(true)}
                    aria-pressed={isAnnual}
                  >
                    Yearly
                  </button>
                </div>
              </div> */}

              {/* Pricing Cards */}
              <div className="max-w-7xl mx-auto grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 items-start">
                {[
                  {
                    name: "Free Plan",
                    price: "Free",
                    description: "Perfect for those just getting started.",
                    features: [
                      "Access to 3 Mock Interviews per month",
                      "Basic Question Bank (Technical, HR, and Behavioral)",
                      "Limited AI Feedback and Analysis",
                      "Basic Resume Review",
                      "Progress Tracking for 1 Profile",
                    ],
                  },
                  {
                    name: "Pro Plan",
                    price: "19.99",
                    description:
                      "Unlock additional features for comprehensive preparation.",
                    features: [
                      "Unlimited Mock Interviews",
                      "Full Access to Advanced Question Bank",
                      "Detailed AI Feedback on Communication, Coding, and Behavioral Skills",
                      "Advanced Resume and Cover Letter Review",
                      "Company-Specific Interview Prep",
                      "Detailed Performance Reports and Study Plans",
                      "Progress Tracking for up to 3 Profiles",
                    ],
                  },
                  {
                    name: "Premium Plan",
                    price: "49.99",
                    popular: true,
                    description:
                      "Go all-in with exclusive features tailored to help you excel.",
                    features: [
                      "Unlimited Mock Interviews with AI and Real Interviewer Feedback",
                      "Access to Premium Question Bank with Exclusive Content",
                      "Priority AI Feedback and Insights",
                      "1-on-1 Career Coaching Sessions (Monthly)",
                      "Advanced Company-Specific Interview Prep",
                      "Resume and Cover Letter Optimization for Specific Roles",
                      "Career Progression Reports",
                      "Progress Tracking for up to 5 Profiles",
                      "Invite-Only Challenges and Leaderboards",
                    ],
                  },
                  {
                    name: "Enterprise Plan",
                    price: "Contact us for pricing",
                    description:
                      "Designed for universities and companies with customized solutions.",
                    features: [
                      "Custom Mock Interview Sessions",
                      "Dedicated Career Coaching and Workshops",
                      "Access to Enterprise-Level Reports and Analytics",
                      "Company-Specific Training and Resource Access",
                      "Progress Tracking for Large Teams or Student Groups",
                    ],
                  },
                ].map((plan, index) => (
                  <div key={index} className="h-full">
                    <div
                      className={`relative flex flex-col h-full p-6 rounded-2xl bg-white dark:bg-slate-900 border border-slate-200 dark:border-slate-900 shadow shadow-slate-950/5 ${
                        plan.popular ? "ring-2 ring-emerald-500" : ""
                      }`}
                    >
                      {plan.popular && (
                        <div className="absolute top-0 right-0 mr-6 -mt-4">
                          <div className="inline-flex items-center text-xs font-semibold py-1.5 px-3 bg-emerald-500 text-white rounded-full shadow-sm shadow-slate-950/5">
                            Most Popular
                          </div>
                        </div>
                      )}

                      <div className="mb-5">
                        <div className="text-slate-900 dark:text-slate-200 font-semibold mb-1">
                          {plan.name}
                        </div>
                        <div className="inline-flex items-baseline mb-2">
                          {plan.price === "Free" ? (
                            <span className="text-slate-900 dark:text-slate-200 font-bold text-4xl">
                              {plan.price}
                            </span>
                          ) : (
                            <>
                              <span className="text-slate-900 dark:text-slate-200 font-bold text-3xl">
                                $
                              </span>
                              <span className="text-slate-900 dark:text-slate-200 font-bold text-4xl">
                                {plan.price}
                              </span>
                              <span className="text-slate-500 font-medium">
                                /mo
                              </span>
                            </>
                          )}
                        </div>
                        <div className="text-sm text-slate-500 mb-5">
                          {plan.description}
                        </div>
                        <a
                          className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150"
                          href="#0"
                        >
                          {plan.price === "Free"
                            ? "Start Now"
                            : "Purchase Plan"}
                        </a>
                      </div>
                      <div className="text-slate-900 dark:text-slate-200 font-medium mb-3">
                        Includes:
                      </div>
                      <ul className="text-slate-600 dark:text-slate-400 text-sm space-y-3 grow">
                        {plan.features.map((feature, i) => (
                          <li key={i} className="flex items-center">
                            <svg
                              className="w-3 h-3 fill-emerald-500 mr-3 shrink-0"
                              viewBox="0 0 12 12"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                            </svg>
                            <span>{feature}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PricingChart;
