import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Box, CircularProgress, Paper, Grow } from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";
import { motion } from "framer-motion";
import MicIcon from "@mui/icons-material/Mic";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate } from "react-router-dom";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: 24,
  backgroundColor: "#ffffff",
  boxShadow: "0 10px 30px rgba(0, 0, 0, 0.08)",
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 15px 35px rgba(0, 0, 0, 0.12)",
  },
}));

const AnswerQuestions = ({ onAllAnswersSubmitted }) => {
  const [questions, setQuestions] = useState(() => {
    const savedQuestions = localStorage.getItem('generatedQuestions');
    return savedQuestions ? JSON.parse(savedQuestions) : [];
  });
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(() => {
    return parseInt(localStorage.getItem('currentQuestionIndex') || '0', 10);
  });
  const [answers, setAnswers] = useState(() => {
    const savedAnswers = localStorage.getItem('interviewAnswers');
    return savedAnswers ? JSON.parse(savedAnswers) : [];
  });
  const [currentAnswer, setCurrentAnswer] = useState("");
  const [loading, setLoading] = useState(false);
  const [questionRead, setQuestionRead] = useState(false);
  const [interviewComplete, setInterviewComplete] = useState(false);
  const [voiceTyping, setVoiceTyping] = useState(false);
  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    localStorage.setItem('currentQuestionIndex', currentQuestionIndex.toString());
    localStorage.setItem('interviewAnswers', JSON.stringify(answers));
  }, [currentQuestionIndex, answers]);

  useEffect(() => {
    if (!questionRead && questions.length > 0) {
      speakText(questions[currentQuestionIndex]);
      setQuestionRead(true);
    }
  }, [currentQuestionIndex, questions, questionRead]);

  const handleSubmitAnswer = async () => {
    if (questions.length === 0) {
      console.error("No questions available");
      return;
    }

    const question = questions[currentQuestionIndex];
    const userId = JSON.parse(localStorage.getItem("user"))?._id;
    setLoading(true);
    try {
      const response = await axios.post(
        `https://hirehack.io/api/interview/analyze-answer/${userId}`,
        {
          question,
          answer: currentAnswer,
        }
      );

      const newAnswers = [
        ...answers,
        {
          question,
          answer: currentAnswer,
          analysis: response.data.analysis || {
            feedback: "No feedback available",
            score: 0,
          },
        },
      ];
      setAnswers(newAnswers);
      setCurrentAnswer("");
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setQuestionRead(false);
      } else {
        setInterviewComplete(true);
        onAllAnswersSubmitted(newAnswers);
        navigate('/analysis'); // Navigate to the analysis page
        localStorage.removeItem('generatedQuestions');
        localStorage.removeItem('currentQuestionIndex');
        localStorage.removeItem('interviewAnswers');
      }
    } catch (error) {
      console.error("Error analyzing answer:", error);
    } finally {
      setLoading(false);
    }
  };

  const startVoiceRecognition = () => {
    if ("webkitSpeechRecognition" in window) {
      const recognition = new window.webkitSpeechRecognition();
      recognition.lang = "en-US";
      recognition.interimResults = false;
      recognition.maxAlternatives = 1;

      recognition.onstart = () => {
        setVoiceTyping(true);
        console.log("Voice typing started.");
      };

      recognition.onend = () => {
        setVoiceTyping(false);
        console.log("Voice typing ended.");
      };

      recognition.onerror = (event) => {
        console.error("Speech recognition error:", event.error);
        setVoiceTyping(false);
      };

      recognition.onresult = (event) => {
        const speechToText = event.results[0][0].transcript;
        console.log("Recognized speech:", speechToText);
        setCurrentAnswer(speechToText);
      };

      recognition.start();
    } else {
      console.error("Speech recognition is not supported in this browser.");
    }
  };

  const speakText = (text) => {
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = "en-US";
    window.speechSynthesis.speak(utterance);
  };

  if (questions.length === 0) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <Typography variant="h5" gutterBottom>
          No questions available. Please generate questions first.
        </Typography>
      </Box>
    );
  }

  if (interviewComplete) {
    return null; // This will be handled by redirection
  }

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      p={4}
      sx={{ background: "linear-gradient(145deg, #f3f4f6, #ffffff)" }}
    >
      <Typography
        variant="h3"
        gutterBottom
        fontWeight="800"
        color="primary"
        mb={4}
        fontFamily="'Poppins', sans-serif"
      >
        Interview Session
      </Typography>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="stretch"
        justifyContent="center"
        width="100%"
        maxWidth="1200px"
        mb={4}
        sx={{ flexWrap: "wrap", gap: 4 }}
      >
        {/* Question */}
        <Grow in={true} timeout={700}>
          <StyledPaper
            elevation={3}
            sx={{ flex: 1, minWidth: "300px", maxWidth: "600px" }}
          >
            <Typography
              variant="h4"
              gutterBottom
              fontWeight="bold"
              fontFamily="'Poppins', sans-serif"
            >
              Question {currentQuestionIndex + 1} of {questions.length}
            </Typography>
            <Typography
              variant="h5"
              gutterBottom
              mb={3}
              color="text.secondary"
              fontFamily="'Roboto', sans-serif"
            >
              {questions[currentQuestionIndex]}
            </Typography>
            <TextField
              label="Your Answer"
              value={currentAnswer}
              onChange={(e) => setCurrentAnswer(e.target.value)}
              fullWidth
              margin="normal"
              multiline
              rows={4}
              variant="outlined"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmitAnswer}
              startIcon={loading ? <CircularProgress size={24} /> : <SendIcon />}
              disabled={loading}
              fullWidth
            >
              {loading ? "Submitting..." : "Submit Answer"}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={startVoiceRecognition}
              disabled={voiceTyping}
              fullWidth
              sx={{ mt: 2 }}
            >
              {voiceTyping ? "Listening..." : <MicIcon />}
              {voiceTyping ? "" : "Start Voice Recognition"}
            </Button>
          </StyledPaper>
        </Grow>
      </Box>
    </Box>
  );
};

export default AnswerQuestions;
