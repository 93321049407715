import React, { useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import TermsAndConditions from "./components/TermsAndConditions";
import Login from "./components/Authentication/Login";
import Register from "./components/Authentication/Register";
import Dashboard from "./components/Dashboard/Dashboard";
import Home from "./components/Home";
import Navbar from "./components/Navbar/Navabar";
import ProtectedRoute from "./components/Authentication/ProtectedRoute";
import PricingChart from "./components/PricingChart";
import Faq from "./components/Faq";
import QuestionGenerator from "./components/UserInterview/QuestionGenerator";
import AnswerQuestions from "./components/UserInterview/AnswerQuestions";
import AnalysisSummary from "./components/UserInterview/AnalysisSummary";
import NewComponent from "./components/Home";
import Footer from "./components/Footer/Footer";


const clientId = "1076418514082-dshqvo16adllnvi809nbl4i4ipmt0c74.apps.googleusercontent.com";

function App() {
  const [step, setStep] = useState(1);
  const [questions, setQuestions] = useState([]);
  const [analyses, setAnalyses] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleQuestionsGenerated = (questions) => {
    setQuestions(questions);
    setStep(2);
  };

  const handleAllAnswersSubmitted = (answers) => {
    setAnalyses(answers);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setStep(3);
    }, 1000);
  };

  return (
    <Box justifyContent="center" alignItems="center" minHeight="100vh" width="100vw">
      <Navbar />
      {loading ? (
        <CircularProgress />
      ) : (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/pricing" element={<PricingChart />} />
          <Route path="/faq" element={<Faq />} />

          {/* Protected routes */}
          <Route
            path="/dashboard"
            element={
              
                <Dashboard />
           
            }
          />
          <Route
            path="/generate"
            element={
              
                <QuestionGenerator onQuestionsGenerated={handleQuestionsGenerated} />
             
            }
          />
          <Route
            path="/answer"
            element={
             
                <AnswerQuestions questions={questions} onAllAnswersSubmitted={handleAllAnswersSubmitted} />
             
            }
          />
          <Route
            path="/analysis"
            element={
              
                <AnalysisSummary analyses={analyses} />
              
            }
          />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/new-component" element={<NewComponent />} />
        </Routes>
      )}
      <Footer />
    </Box>
  );
}

function AppWrapper() {
  return (
    <Provider store={store}>
      <GoogleOAuthProvider clientId={clientId}>
        <Router>
          <App />
        </Router>
      </GoogleOAuthProvider>
    </Provider>
  );
}

export default AppWrapper;
