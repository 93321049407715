// import React, { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { loginUser } from "../../redux/features/authSlice";
// import {
//   Button,
//   TextField,
//   CircularProgress,
//   Box,
//   Typography,
//   Checkbox,
//   FormControlLabel,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
// } from "@mui/material";
// import { toast } from "sonner";
// import { useNavigate } from "react-router-dom";
// import { GoogleLogin } from "@react-oauth/google";

// const Login = () => {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [openDialog, setOpenDialog] = useState(false);
//   const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
//   const [otp, setOtp] = useState("");
//   const [newPassword, setNewPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const dispatch = useDispatch();
//   const { status, error } = useSelector((state) => state.auth);
//   const navigate = useNavigate(); // For redirection

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     dispatch(loginUser({ email, password }))
//       .unwrap()
//       .then((response) => {
//         localStorage.setItem("token", response.token);
//         toast.success("Login successful");
//         navigate("/generate");
//       })
//       .catch((error) => {
//         toast.error(error.message || "Failed to login");
//       });
//   };

//   const handleForgotPassword = () => {
//     setOpenDialog(true);
//   };

//   const handleSendOtp = async () => {
//     try {
//       const res = await fetch(
//         "https://hirehack.io/api/user/request-reset-password",
//         {
//           method: "POST",
//           headers: { "Content-Type": "application/json" },
//           body: JSON.stringify({ email: forgotPasswordEmail }),
//         }
//       );
//       const data = await res.json();
//       if (data.success) {
//         toast.success("OTP sent to your email");
//       } else {
//         toast.error(data.message || "Failed to send OTP");
//       }
//     } catch (error) {
//       toast.error("Failed to send OTP");
//     }
//   };

//   const handleResetPassword = async () => {
//     if (newPassword !== confirmPassword) {
//       toast.error("Passwords do not match");
//       return;
//     }

//     try {
//       const res = await fetch(
//         "https://hirehack.io/api/user/verify-otp-and-reset-password",
//         {
//           method: "POST",
//           headers: { "Content-Type": "application/json" },
//           body: JSON.stringify({
//             email: forgotPasswordEmail,
//             otp,
//             newPassword,
//           }),
//         }
//       );
//       const data = await res.json();
//       if (data.success) {
//         toast.success("Password reset successfully");
//         setOpenDialog(false);
//       } else {
//         toast.error(data.message || "Failed to reset password");
//       }
//     } catch (error) {
//       toast.error("Failed to reset password");
//     }
//   };

//   const handleGoogleLoginSuccess = async (response) => {
//     try {
//       const { tokenId } = response;
//       const res = await fetch("https://hirehack.io/api/user/google-login", {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({ tokenId }),
//       });
//       const data = await res.json();
//       if (data.success) {
//         localStorage.setItem("token", data.token);
//         toast.success("Google login successful");
//         navigate("/generate");
//       } else {
//         toast.error(data.message || "Failed to login with Google");
//       }
//     } catch (error) {
//       toast.error("Failed to login with Google");
//     }
//   };

//   return (
//     <Box
//       sx={{
//         backgroundColor: "#f9fafb",
//         minHeight: "100vh",
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//         justifyContent: "center",
//         padding: 2,
//       }}
//     >
//       <Box
//         sx={{
//           width: "100%",
//           maxWidth: 400,
//           backgroundColor: "#ffffff",
//           borderRadius: 2,
//           boxShadow: 3,
//           padding: 3,
//         }}
//       >
//         <Box
//           display="flex"
//           alignItems="center"
//           justifyContent="center"
//           mb={3}
//           style={{ textAlign: "center" }}
//         >
//           <Typography variant="h6" color="textPrimary">
//             HireHack
//           </Typography>
//         </Box>
//         <Typography variant="h5" gutterBottom>
//           Log in
//         </Typography>
//         <form onSubmit={handleSubmit}>
//           <Box mb={2}>
//             <TextField
//               fullWidth
//               label="Your email"
//               variant="outlined"
//               type="email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               placeholder="name@company.com"
//               required
//               sx={{
//                 backgroundColor: "#f9fafb",
//                 borderColor: "#d1d5db",
//                 "& .MuiInputBase-input": {
//                   color: "#1f2937",
//                 },
//                 "& .MuiOutlinedInput-root": {
//                   borderRadius: 1,
//                   "& fieldset": {
//                     borderColor: "#d1d5db",
//                   },
//                   "&:hover fieldset": {
//                     borderColor: "#a1a1aa",
//                   },
//                   "&.Mui-focused fieldset": {
//                     borderColor: "#6200ea",
//                   },
//                 },
//               }}
//             />
//           </Box>
//           <Box mb={2}>
//             <TextField
//               fullWidth
//               label="Password"
//               variant="outlined"
//               type="password"
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//               placeholder="••••••••"
//               required
//               sx={{
//                 backgroundColor: "#f9fafb",
//                 borderColor: "#d1d5db",
//                 "& .MuiInputBase-input": {
//                   color: "#1f2937",
//                 },
//                 "& .MuiOutlinedInput-root": {
//                   borderRadius: 1,
//                   "& fieldset": {
//                     borderColor: "#d1d5db",
//                   },
//                   "&:hover fieldset": {
//                     borderColor: "#a1a1aa",
//                   },
//                   "&.Mui-focused fieldset": {
//                     borderColor: "#6200ea",
//                   },
//                 },
//               }}
//             />
//           </Box>
//           <Box display="flex" justifyContent="end" mb={2}>
//             {/* <FormControlLabel
//               control={<Checkbox color="primary" />}
//               label="Remember me"
//             /> */}
//             <Typography
//               variant="body2"
//               color="primary"
//               sx={{ cursor: "pointer" }}
//               onClick={handleForgotPassword}
//             >
//               Forgot password?
//             </Typography>
//           </Box>
//           <Button
//             type="submit"
//             variant="contained"
//             color="primary"
//             fullWidth
//             sx={{
//               mb: 2,
//               py: 1.5,
//               fontWeight: "medium",
//             }}
//             disabled={status === "loading"}
//           >
//             {status === "loading" ? <CircularProgress size={24} /> : "Sign in"}
//           </Button>
//           <Box mb={2}>
//             <GoogleLogin
//               onSuccess={handleGoogleLoginSuccess}
//               onFailure={(error) => toast.error("Google login failed")}
//               // Assuming you are using Google Login library version >= 6.0.0
//             />
//           </Box>
//           {error && (
//             <Typography color="error" variant="body2" sx={{ mb: 2 }}>
//               {error}
//             </Typography>
//           )}
//           <Typography variant="body2" color="textSecondary">
//             Don’t have an account yet?{" "}
//             <a
//               href="/register"
//               style={{ color: "#6200ea", textDecoration: "underline" }}
//             >
//               Sign up
//             </a>
//           </Typography>
//         </form>
//       </Box>

//       {/* Forgot Password Dialog */}
//       <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
//         <DialogTitle>Forgot Password</DialogTitle>
//         <DialogContent>
//           <TextField
//             autoFocus
//             margin="dense"
//             label="Email Address"
//             type="email"
//             fullWidth
//             variant="outlined"
//             value={forgotPasswordEmail}
//             onChange={(e) => setForgotPasswordEmail(e.target.value)}
//             placeholder="name@company.com"
//             sx={{ mb: 2 }}
//           />
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={handleSendOtp}
//             fullWidth
//             sx={{ mb: 2 }}
//           >
//             Send OTP
//           </Button>
//           <TextField
//             margin="dense"
//             label="OTP"
//             type="text"
//             fullWidth
//             variant="outlined"
//             value={otp}
//             onChange={(e) => setOtp(e.target.value)}
//             placeholder="Enter OTP"
//             sx={{ mb: 2 }}
//           />
//           <TextField
//             margin="dense"
//             label="New Password"
//             type="password"
//             fullWidth
//             variant="outlined"
//             value={newPassword}
//             onChange={(e) => setNewPassword(e.target.value)}
//             placeholder="••••••••"
//             sx={{ mb: 2 }}
//           />
//           <TextField
//             margin="dense"
//             label="Confirm New Password"
//             type="password"
//             fullWidth
//             variant="outlined"
//             value={confirmPassword}
//             onChange={(e) => setConfirmPassword(e.target.value)}
//             placeholder="••••••••"
//             sx={{ mb: 2 }}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenDialog(false)} color="primary">
//             Cancel
//           </Button>
//           <Button
//             onClick={handleResetPassword}
//             variant="contained"
//             color="primary"
//           >
//             Reset Password
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// };

// export default Login;

// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { loginUser } from "../../redux/features/authSlice";
// import HirehackLogin from "../../Images/hireHack.svg";
// import {
//   Button,
//   TextField,
//   CircularProgress,
//   Box,
//   Typography,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
// } from "@mui/material";
// import { toast } from "sonner";
// import { useNavigate } from "react-router-dom";
// import { GoogleLogin } from "@react-oauth/google";

// const Login = () => {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [openDialog, setOpenDialog] = useState(false);
//   const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
//   const [otp, setOtp] = useState("");
//   const [newPassword, setNewPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [otpSent, setOtpSent] = useState(false); // New state for OTP sent status
//   const [timer, setTimer] = useState(0); // New state for countdown timer
//   const [fieldsDisabled, setFieldsDisabled] = useState(true); // New state for field enable/disable
//   const dispatch = useDispatch();
//   const { status, error } = useSelector((state) => state.auth);
//   const navigate = useNavigate(); // For redirection

//   useEffect(() => {
//     let interval;
//     if (otpSent && timer > 0) {
//       interval = setInterval(() => {
//         setTimer((prev) => prev - 1);
//       }, 1000);
//     } else if (timer === 0) {
//       setOtpSent(false);
//       setFieldsDisabled(true);
//     }

//     return () => clearInterval(interval);
//   }, [otpSent, timer]);

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     dispatch(loginUser({ email, password }))
//       .unwrap()
//       .then((response) => {
//         localStorage.setItem("token", response.token);
//         toast.success("Login successful");
//         navigate("/generate");
//       })
//       .catch((error) => {
//         toast.error(error.message || "Failed to login");
//       });
//   };

//   const handleForgotPassword = () => {
//     setOpenDialog(true);
//   };

//   const handleSendOtp = async () => {
//     try {
//       const res = await fetch(
//         "https://hirehack.io/api/user/request-reset-password",
//         {
//           method: "POST",
//           headers: { "Content-Type": "application/json" },
//           body: JSON.stringify({ email: forgotPasswordEmail }),
//         }
//       );
//       const data = await res.json();
//       if (data.success) {
//         setOtpSent(true);
//         setTimer(5 * 60); // 5 minutes in seconds
//         setFieldsDisabled(false); // Enable fields after OTP is sent
//         toast.success("OTP sent to your email");
//       } else {
//         toast.error(data.message || "Failed to send OTP");
//       }
//     } catch (error) {
//       toast.error("Failed to send OTP");
//     }
//   };

//   const handleResetPassword = async () => {
//     if (newPassword !== confirmPassword) {
//       toast.error("Passwords do not match");
//       return;
//     }

//     try {
//       const res = await fetch(
//         "https://hirehack.io/api/user/verify-otp-and-reset-password",
//         {
//           method: "POST",
//           headers: { "Content-Type": "application/json" },
//           body: JSON.stringify({
//             email: forgotPasswordEmail,
//             otp,
//             newPassword,
//           }),
//         }
//       );
//       const data = await res.json();
//       if (data.success) {
//         toast.success("Password reset successfully");
//         setOpenDialog(false);
//       } else {
//         toast.error(data.message || "Failed to reset password");
//       }
//     } catch (error) {
//       toast.error("Failed to reset password");
//     }
//   };

//   const handleGoogleLoginSuccess = async (response) => {
//     try {
//       const { tokenId } = response;
//       const res = await fetch("https://hirehack.io/api/user/google-login", {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({ tokenId }),
//       });
//       const data = await res.json();
//       if (data.success) {
//         localStorage.setItem("token", data.token);
//         toast.success("Google login successful");
//         navigate("/generate");
//       } else {
//         toast.error(data.message || "Failed to login with Google");
//       }
//     } catch (error) {
//       toast.error("Failed to login with Google");
//     }
//   };

//   return (
//     <Box
//       sx={{
//         backgroundColor: "#f9fafb",
//         minHeight: "100vh",
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//         justifyContent: "center",
//         padding: 2,
//       }}
//     >
//       <Box
//         sx={{
//           width: "100%",
//           maxWidth: 400,
//           backgroundColor: "#ffffff",
//           borderRadius: 2,
//           boxShadow: 3,
//           padding: 3,
//         }}
//       >
//         <Box
//           display="flex"
//           alignItems="center"
//           justifyContent="center"
//           mb={3}
//           style={{ textAlign: "center" }}
//         >
//           {/* <Typography variant="h6" color="textPrimary">
//             HireHack
//           </Typography> */}
//           <img src={HirehackLogin} alt="NoLogo"></img>
//         </Box>
//         {/* <Typography variant="h5" gutterBottom>
//           Log in
//         </Typography> */}
//         <form onSubmit={handleSubmit}>
//           <Box mb={2}>
//             <TextField
//               fullWidth
//               label="Your email"
//               variant="outlined"
//               type="email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               placeholder="name@company.com"
//               required
//               sx={{
//                 backgroundColor: "#f9fafb",
//                 borderColor: "#d1d5db",
//                 "& .MuiInputBase-input": {
//                   color: "#1f2937",
//                 },
//                 "& .MuiOutlinedInput-root": {
//                   borderRadius: 1,
//                   "& fieldset": {
//                     borderColor: "#d1d5db",
//                   },
//                   "&:hover fieldset": {
//                     borderColor: "#a1a1aa",
//                   },
//                   "&.Mui-focused fieldset": {
//                     borderColor: "#6200ea",
//                   },
//                 },
//               }}
//             />
//           </Box>
//           <Box mb={2}>
//             <TextField
//               fullWidth
//               label="Password"
//               variant="outlined"
//               type="password"
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//               placeholder="••••••••"
//               required
//               sx={{
//                 backgroundColor: "#f9fafb",
//                 borderColor: "#d1d5db",
//                 "& .MuiInputBase-input": {
//                   color: "#1f2937",
//                 },
//                 "& .MuiOutlinedInput-root": {
//                   borderRadius: 1,
//                   "& fieldset": {
//                     borderColor: "#d1d5db",
//                   },
//                   "&:hover fieldset": {
//                     borderColor: "#a1a1aa",
//                   },
//                   "&.Mui-focused fieldset": {
//                     borderColor: "#6200ea",
//                   },
//                 },
//               }}
//             />
//           </Box>
//           <Box display="flex" justifyContent="end" mb={2}>
//             {/* <FormControlLabel
//               control={<Checkbox color="primary" />}
//               label="Remember me"
//             /> */}
//             <Typography
//               variant="body2"
//               color="primary"
//               sx={{ cursor: "pointer" }}
//               onClick={handleForgotPassword}
//             >
//               Forgot password?
//             </Typography>
//           </Box>
//           <Button
//             type="submit"
//             variant="contained"
//             color="primary"
//             fullWidth
//             sx={{
//               mb: 2,
//               py: 1.5,
//               fontWeight: "medium",
//             }}
//             disabled={status === "loading"}
//           >
//             {status === "loading" ? <CircularProgress size={24} /> : "Sign in"}
//           </Button>
//           <Box mb={2} style={{ width: "100%" }}>
//             <GoogleLogin
//               onSuccess={handleGoogleLoginSuccess}
//               onFailure={(error) => toast.error("Google login failed")}
//               width={0}
//               style={{ width: "100%" }}
//               // Assuming you are using Google Login library version >= 6.0.0
//             />
//           </Box>
//           {error && (
//             <Typography color="error" variant="body2" sx={{ mb: 2 }}>
//               {error}
//             </Typography>
//           )}
//           <Typography variant="body2" color="textSecondary">
//             Don’t have an account yet?{" "}
//             <a
//               href="/register"
//               style={{ color: "#6200ea", textDecoration: "underline" }}
//             >
//               Sign up
//             </a>
//           </Typography>
//         </form>
//       </Box>

//       {/* Forgot Password Dialog */}
//       <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
//         <DialogTitle>Forgot Password</DialogTitle>
//         <DialogContent>
//           <Box component="form">
//             {!otpSent ? (
//               <>
//                 <TextField
//                   fullWidth
//                   label="Email"
//                   variant="outlined"
//                   type="email"
//                   value={forgotPasswordEmail}
//                   onChange={(e) => setForgotPasswordEmail(e.target.value)}
//                   placeholder="name@company.com"
//                   required
//                   sx={{ mb: 2 }}
//                 />
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={handleSendOtp}
//                   fullWidth
//                   disabled={fieldsDisabled}
//                 >
//                   Send OTP
//                 </Button>
//               </>
//             ) : (
//               <>
//                 <Typography variant="body1" gutterBottom>
//                   {timer > 0
//                     ? `OTP expires in ${Math.floor(timer / 60)}:${(timer % 60)
//                         .toString()
//                         .padStart(2, "0")}`
//                     : "OTP expired"}
//                 </Typography>
//                 <TextField
//                   fullWidth
//                   label="OTP"
//                   variant="outlined"
//                   value={otp}
//                   onChange={(e) => setOtp(e.target.value)}
//                   placeholder="123456"
//                   required
//                   sx={{ mb: 2 }}
//                   disabled={fieldsDisabled}
//                 />
//                 <TextField
//                   fullWidth
//                   label="New Password"
//                   variant="outlined"
//                   type="password"
//                   value={newPassword}
//                   onChange={(e) => setNewPassword(e.target.value)}
//                   placeholder="••••••••"
//                   required
//                   sx={{ mb: 2 }}
//                   disabled={fieldsDisabled}
//                 />
//                 <TextField
//                   fullWidth
//                   label="Confirm Password"
//                   variant="outlined"
//                   type="password"
//                   value={confirmPassword}
//                   onChange={(e) => setConfirmPassword(e.target.value)}
//                   placeholder="••••••••"
//                   required
//                   sx={{ mb: 2 }}
//                   disabled={fieldsDisabled}
//                 />
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={handleResetPassword}
//                   fullWidth
//                   disabled={fieldsDisabled}
//                 >
//                   Reset Password
//                 </Button>
//               </>
//             )}
//           </Box>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenDialog(false)} color="primary">
//             Close
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// };

// export default Login;
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../redux/features/authSlice";
import HirehackLogin from "../../Images/hireHack.svg";
import {
  Button,
  TextField,
  CircularProgress,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import OtpInput from "react-otp-input";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [timer, setTimer] = useState(0);
  const [fieldsDisabled, setFieldsDisabled] = useState(true);
  const dispatch = useDispatch();
  const { status, error } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    let interval;
    if (otpSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setOtpSent(false);
      setFieldsDisabled(true);
    }

    return () => clearInterval(interval);
  }, [otpSent, timer]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loginUser({ email, password }))
      .unwrap()
      .then((response) => {
        // Store token and user information in localStorage
        localStorage.setItem("token", response.token);
        localStorage.setItem("user", JSON.stringify(response.user));
        toast.success("Login successful");
        navigate("/dashboard");
      })
      .catch((error) => {
        toast.error(error.message || "Failed to login");
      });
  };

  const handleForgotPassword = () => {
    setOpenDialog(true);
  };

  const handleSendOtp = async () => {
    try {
      const res = await fetch(
        "https://hirehack.io/api/user/request-reset-password",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email: forgotPasswordEmail }),
        }
      );
      const data = await res.json();
      if (data.success) {
        setOtpSent(true);
        setTimer(5 * 60); // 5 minutes in seconds
        setFieldsDisabled(false);
        toast.success("OTP sent to your email");
      } else {
        toast.error(data.message || "Failed to send OTP");
      }
    } catch (error) {
      toast.error("Failed to send OTP");
    }
  };

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
  
    try {
      const res = await fetch(
        "https://hirehack.io/api/user/verify-otp-reset-password",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email: forgotPasswordEmail,
            otp,
            newPassword,
          }),
        }
      );
      const data = await res.json();
      if (data.success) {
        toast.success("Password reset successfully");
  
        // Close the dialog and redirect to the login page
        setOpenDialog(false);
        setTimeout(() => {
          navigate("/login");
        }, 1500); // Delay for the toast to appear before redirecting
      } else {
        toast.error(data.message || "Failed to reset password");
      }
    } catch (error) {
      toast.error("Failed to reset password");
    }
  };
  

  const handleGoogleLoginSuccess = async (response) => {
    try {
      const { tokenId } = response;
      const res = await fetch("https://hirehack.io/api/user/google-login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ tokenId }),
      });
      const data = await res.json();
      if (data.success) {
        localStorage.setItem("token", data.token);
        localStorage.setItem("user", JSON.stringify(data.user));
        toast.success("Google login successful");
        navigate("/generate");
      } else {
        toast.error(data.message || "Failed to login with Google");
      }
    } catch (error) {
      toast.error("Failed to login with Google");
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#f9fafb",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 400,
          backgroundColor: "#ffffff",
          borderRadius: 2,
          boxShadow: 3,
          padding: 3,
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          mb={3}
          style={{ textAlign: "center" }}
        >
          <img src={HirehackLogin} alt="NoLogo"></img>
        </Box>
        <form onSubmit={handleSubmit}>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Your email"
              variant="outlined"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="name@company.com"
              required
              sx={{
                backgroundColor: "#f9fafb",
                borderColor: "#d1d5db",
                "& .MuiInputBase-input": {
                  color: "#1f2937",
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: 1,
                  "& fieldset": {
                    borderColor: "#d1d5db",
                  },
                  "&:hover fieldset": {
                    borderColor: "#a1a1aa",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#6200ea",
                  },
                },
              }}
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Password"
              variant="outlined"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="••••••••"
              required
              sx={{
                backgroundColor: "#f9fafb",
                borderColor: "#d1d5db",
                "& .MuiInputBase-input": {
                  color: "#1f2937",
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: 1,
                  "& fieldset": {
                    borderColor: "#d1d5db",
                  },
                  "&:hover fieldset": {
                    borderColor: "#a1a1aa",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#6200ea",
                  },
                },
              }}
            />
          </Box>
          <Box display="flex" justifyContent="end" mb={2}>
            <Typography
              variant="body2"
              color="primary"
              sx={{ cursor: "pointer" }}
              onClick={handleForgotPassword}
            >
              Forgot password?
            </Typography>
          </Box>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              mb: 2,
              py: 1.5,
              fontWeight: "medium",
            }}
            disabled={status === "loading"}
          >
            {status === "loading" ? <CircularProgress size={24} /> : "Sign in"}
          </Button>
          <Box
            mb={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <GoogleLogin
              onSuccess={handleGoogleLoginSuccess}
              onFailure={(error) => toast.error("Google login failed")}
              width={0}
              style={{ width: "100%" }}
            />
          </Box>
          {error && (
            <Typography color="error" variant="body2" sx={{ mb: 2 }}>
              {error}
            </Typography>
          )}
          <Typography variant="body2" color="textSecondary">
            Don’t have an account yet?{" "}
            <a
              href="/register"
              style={{ color: "#6200ea", textDecoration: "underline" }}
            >
              Sign up
            </a>
          </Typography>
        </form>
      </Box>

      {/* Forgot Password Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Forgot Password</DialogTitle>
        <DialogContent>
          {!otpSent ? (
            <>
              <TextField
                label="Email Address"
                type="email"
                fullWidth
                margin="normal"
                value={forgotPasswordEmail}
                onChange={(e) => setForgotPasswordEmail(e.target.value)}
                sx={{ mb: 2 }}
              />
              <Button
                onClick={handleSendOtp}
                variant="contained"
                color="primary"
                fullWidth
                disabled={!forgotPasswordEmail}
              >
                Send OTP
              </Button>
            </>
          ) : (
            <>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  separator={<span>-</span>}
                  inputStyle={{
                    width: "3rem",
                    height: "3rem",
                    margin: "0 0.5rem",
                    fontSize: "1.5rem",
                    borderRadius: "0.5rem",
                    border: "1px solid #d1d5db",
                  }}
                  isInputNum
                  shouldAutoFocus
                  renderInput={(props) => <input {...props} />}
                  disabled={fieldsDisabled}
                />
              </Box>
              <TextField
                label="New Password"
                type="password"
                fullWidth
                margin="normal"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                label="Confirm Password"
                type="password"
                fullWidth
                margin="normal"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                sx={{ mb: 2 }}
              />
              <Button
                onClick={handleResetPassword}
                variant="contained"
                color="primary"
                fullWidth
                disabled={
                  !otp || !newPassword || newPassword !== confirmPassword
                }
              >
                Reset Password
              </Button>
              {timer > 0 && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="center"
                >
                  Time remaining: {Math.floor(timer / 60)}:{timer % 60}
                </Typography>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Login;
