import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar/Navabar'; // Fixed typo: Navabar to Navbar
import '@fontsource/poppins';  // Import Poppins font

const Home = () => {
  const [started, setStarted] = useState(false);
  const navigate = useNavigate();
  const [optionIndex, setOptionIndex] = useState(0);
  const options = ['Software Engineering', 'Coding', 'Designing', 'Data Science', 'Cybersecurity', 'Product Management'];

  const startPracticing = () => {
    setStarted(true);
    setTimeout(() => navigate('/login'), 1000);
  };

  const showPricingChart = () => {
    setStarted(true);
    setTimeout(() => navigate('/pricing'), 1000);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setOptionIndex((prevIndex) => (prevIndex + 1) % options.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [options.length]);

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-white to-gray-50 overflow-hidden font-poppins">
      {/* <Navbar /> */}

      <main className="flex-grow">
        <section className="flex flex-col items-center justify-center bg-gradient-to-b from-blue-200 to-white py-16 min-h-screen relative">
          {!started ? (
            <motion.div
              className="text-center w-full max-w-5xl px-4"
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.7, ease: "easeInOut" }}
            >
              <h1 className="text-4xl md:text-6xl lg:text-7xl font-extrabold text-gray-800 mb-6">
                <span className="text-gray-700">Interview </span>
                <motion.span
                  className="text-indigo-600 ml-2"
                  initial={{ x: -20, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.6, ease: "easeOut" }}
                >
                  Preparation
                </motion.span>
              </h1>
              <p className="text-lg md:text-xl text-gray-600 mb-4 max-w-3xl mx-auto">
                Your one-stop solution for preparing for interviews across leading companies.
              </p>
              <motion.div
                className="flex justify-center items-center mb-12"
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.8, ease: "easeInOut", staggerChildren: 0.2 }}
              >
                <motion.div
                  className="text-2xl md:text-3xl text-indigo-600 font-semibold"
                  key={optionIndex}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.5, ease: "easeInOut" }}
                >
                  {options[optionIndex]}
                </motion.div>
              </motion.div>
              <p className="text-base md:text-lg text-gray-600 mb-10 max-w-3xl mx-auto">
                Prepare for interviews at over 1000+ companies with targeted questions and expert feedback.
              </p>
              <motion.button
                className="bg-gradient-to-r from-indigo-500 to-purple-600 text-white py-3 px-6 md:py-4 md:px-12 rounded-full text-lg md:text-xl font-semibold hover:from-indigo-600 hover:to-purple-700 transition duration-300 shadow-xl"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                onClick={startPracticing}
              >
                Start Preparation
              </motion.button>
            </motion.div>
          ) : (
            <motion.div
              className="w-full max-w-4xl mx-auto px-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <div>Redirecting...</div>
            </motion.div>
          )}
        </section>

        <section className="bg-gray-100 py-16 md:py-24">
          <div className="max-w-6xl mx-auto text-center px-4">
            <motion.h2
              className="text-3xl md:text-5xl font-bold text-gray-900 mb-6"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              Why Choose Us?
            </motion.h2>
            <motion.p
              className="text-base md:text-lg text-gray-700 max-w-3xl mx-auto"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              We provide personalized interview preparation resources, with expert feedback tailored to your role and industry.
            </motion.p>
          </div>
        </section>

        <section className="bg-indigo-600 py-16 md:py-24 text-white">
          <div className="max-w-6xl mx-auto text-center px-4">
            <motion.h2
              className="text-3xl md:text-5xl font-extrabold mb-6"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              How It Works
            </motion.h2>
            <motion.div
              className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 text-left"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              <motion.div
                className="p-6 bg-indigo-500 rounded-lg shadow-xl hover:shadow-2xl transition-shadow duration-300"
                whileHover={{ scale: 1.05 }}
              >
                <h3 className="text-xl md:text-2xl font-bold mb-4">Step 1</h3>
                <p>Pick your interview type and company.</p>
              </motion.div>
              <motion.div
                className="p-6 bg-indigo-500 rounded-lg shadow-xl hover:shadow-2xl transition-shadow duration-300"
                whileHover={{ scale: 1.05 }}
              >
                <h3 className="text-xl md:text-2xl font-bold mb-4">Step 2</h3>
                <p>Generate and practice questions specific to your role.</p>
              </motion.div>
              <motion.div
                className="p-6 bg-indigo-500 rounded-lg shadow-xl hover:shadow-2xl transition-shadow duration-300"
                whileHover={{ scale: 1.05 }}
              >
                <h3 className="text-xl md:text-2xl font-bold mb-4">Step 3</h3>
                <p>Get detailed feedback and improve your skills.</p>
              </motion.div>
            </motion.div>
          </div>
        </section>

        <motion.section
          className="bg-gradient-to-b from-indigo-600 to-purple-700 py-16 md:py-24 text-white"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <div className="max-w-6xl mx-auto text-center px-4">
            <h2 className="text-3xl md:text-5xl font-bold mb-6">
              Ready to Ace Your Interview?
            </h2>
            <motion.button
              className="bg-white text-indigo-600 py-3 px-6 md:py-4 md:px-12 rounded-full text-lg md:text-xl font-semibold hover:bg-gray-100 transition duration-300 shadow-lg"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              onClick={showPricingChart}
            >
              Start Now
            </motion.button>
          </div>
        </motion.section>
      </main>

      <footer className="bg-gray-900 py-8 md:py-12 text-white">
        <motion.div
          className="max-w-6xl mx-auto text-center px-4"
          initial={{ opacity: 0, scale: 0.95 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}
        >
          <p className="text-base md:text-lg font-medium mb-6">© 2024 InterviewPrep. All rights reserved.</p>
          <motion.div
            className="flex justify-center space-x-6 md:space-x-8"
            whileHover={{ scale: 1.2 }}
          >
            <a href="#" className="text-white hover:text-gray-400">About Us</a>
            <a href="#" className="text-white hover:text-gray-400">Contact</a>
            <a href="#" className="text-white hover:text-gray-400">Privacy Policy</a>
          </motion.div>
        </motion.div>
      </footer>
    </div>
  );
};

export default Home;
