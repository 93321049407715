import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  useTheme,
  ThemeProvider,
  createTheme,
  alpha,
  Container,
  Grid,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import { motion } from "framer-motion";
import axios from "axios";
import { QuestionMark as QuestionIcon } from "@mui/icons-material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS
import { useNavigate } from 'react-router-dom';

const topics = [
  "Software Engineering",
  "Data Science",
  "Product Management",
  "Design",
  "Marketing",
  "Sales",
  "Finance",
  "Human Resources",
];

const interviewTypes = [
  "Technical",
  "Behavioral",
  "HR",
  "Case Study",
  "Product",
];

const presetCompanies = [
  "TCS",
  "Accenture",
  "Infosys",
  "Wipro",
  "Cognizant",
  "HCL",
  "Capgemini",
  "IBM",
  "Oracle",
  "SAP",
];

// Enhanced custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#4A148C", // A deeper purple
    },
    secondary: {
      main: "#FF6D00", // A vibrant orange for accents
    },
    background: {
      default: "#F0F4F8", // A light blue-gray background
      paper: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: "'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    h4: {
      fontWeight: 700,
    },
  },
  shape: {
    borderRadius: 16,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: 600,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: alpha("#4A148C", 0.5),
          },
        },
      },
    },
  },
});

const QuestionGenerator = ({ onQuestionsGenerated }) => {
  const navigate = useNavigate(); 
  const [topic, setTopic] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [interviewType, setInterviewType] = useState("");
  const [difficultyLevel, setDifficultyLevel] = useState("");

  // Retrieve userId from localStorage
  const userId = JSON.parse(localStorage.getItem("user"))?._id;

  const handleGenerateQuestions = async () => {
    let minQuestions, maxQuestions;

    switch (difficultyLevel) {
      case "Easy":
        minQuestions = 2;
        maxQuestions = 5;
        break;
      case "Medium":
        minQuestions = 5;
        maxQuestions = 10;
        break;
      case "Hard":
        minQuestions = 10;
        maxQuestions = 20;
        break;
      default:
        minQuestions = 1;
        maxQuestions = 1;
    }

    const numQuestions =
      Math.floor(Math.random() * (maxQuestions - minQuestions + 1)) + minQuestions;

    try {
      const response = await axios.post(
        `https://hirehack.io/api/interview/generate-questions/${userId}`,
        {
          topic,
          companyName,
          interviewType,
          numQuestions,
        }
      );
      
      const generatedQuestions = response.data.questions;
      
      // Store questions in localStorage
      localStorage.setItem("generatedQuestions", JSON.stringify(generatedQuestions));

      // Redirect to /answer
      navigate('/answer');

      onQuestionsGenerated(generatedQuestions);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        toast.error("Free interview already used. Please make a payment to continue.");
      } else {
        toast.error("An error occurred while generating questions.");
      }
      console.error("Error generating questions:", error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md">
        <Box
          component={motion.div}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
          py={4}
        >
          <Card
            component={motion.div}
            whileHover={{ boxShadow: theme.shadows[10] }}
            transition={{ type: "spring", stiffness: 300 }}
            sx={{
              width: "100%",
              overflow: "hidden",
              boxShadow: theme.shadows[3],
              background: "linear-gradient(145deg, #ffffff 0%, #f0f0f0 100%)",
            }}
          >
            <CardContent sx={{ p: 4 }}>
              <Box display="flex" alignItems="center" mb={4}>
                <QuestionIcon
                  sx={{ fontSize: 48, color: "primary.main", mr: 2 }}
                />
                <Typography
                  component={motion.h1}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.2 }}
                  variant="h4"
                  color="primary"
                >
                  Interview Q&A Generator
                </Typography>
              </Box>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    freeSolo
                    options={presetCompanies}
                    value={companyName}
                    onChange={(event, newValue) => setCompanyName(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Company Name"
                        fullWidth
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="interview-type-select-label">
                      Interview Type
                    </InputLabel>
                    <Select
                      labelId="interview-type-select-label"
                      value={interviewType}
                      onChange={(e) => setInterviewType(e.target.value)}
                      label="Interview Type"
                    >
                      <MenuItem value="" disabled>
                        Select an interview type
                      </MenuItem>
                      {interviewTypes.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="topic-select-label">
                      Select a Topic
                    </InputLabel>
                    <Select
                      labelId="topic-select-label"
                      value={topic}
                      onChange={(e) => setTopic(e.target.value)}
                      label="Select a Topic"
                    >
                      <MenuItem value="" disabled>
                        Select a topic
                      </MenuItem>
                      {topics.map((topicOption) => (
                        <MenuItem key={topicOption} value={topicOption}>
                          {topicOption}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="difficulty-level-select-label">
                      Difficulty Level
                    </InputLabel>
                    <Select
                      labelId="difficulty-level-select-label"
                      value={difficultyLevel}
                      onChange={(e) => setDifficultyLevel(e.target.value)}
                      label="Difficulty Level"
                    >
                      <MenuItem value="" disabled>
                        Select a difficulty level
                      </MenuItem>
                      <MenuItem value="Easy">Easy</MenuItem>
                      <MenuItem value="Medium">Medium</MenuItem>
                      <MenuItem value="Hard">Hard</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Box mt={4}>
                <Button
                  component={motion.button}
                  whileHover={{ scale: 1.02, boxShadow: theme.shadows[8] }}
                  whileTap={{ scale: 0.98 }}
                  variant="contained"
                  color="primary"
                  onClick={handleGenerateQuestions}
                  fullWidth
                  size="large"
                  sx={{
                    py: 2,
                    fontWeight: "bold",
                    background:
                      "linear-gradient(45deg, #4A148C 30%, #7B1FA2 90%)",
                    "&:hover": {
                      background:
                        "linear-gradient(45deg, #7B1FA2 30%, #4A148C 90%)",
                    },
                  }}
                >
                  Start Interview
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Container>
      <ToastContainer /> 
    </ThemeProvider>
  );
};

export default QuestionGenerator;
