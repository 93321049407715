import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Define base URL for the API
const API_BASE_URL = "https://hirehack.io/api/user"; // Change this URL as needed

// Thunk for registering a user
export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_BASE_URL}`, userData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || { message: 'Registration failed' });
    }
  }
);

// Thunk for verifying OTP
export const verifyOTP = createAsyncThunk(
  "auth/verifyOTP",
  async ({ email, otp }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/verify-user`, { email, otp });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || { message: 'OTP verification failed' });
    }
  }
);

// Thunk for logging in a user
export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/login`, credentials);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk for requesting password reset
export const requestResetPassword = createAsyncThunk(
  "auth/requestResetPassword",
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/request-reset-password`, { email });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk for verifying OTP and resetting password
export const verifyOTPAndResetPassword = createAsyncThunk(
  "auth/verifyOTPAndResetPassword",
  async ({ email, otp, newPassword }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/verify-otp-reset-password`, {
        email,
        otp,
        newPassword,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    token: null,
    status: "idle",
    otpVerified: false,
    passwordReset: false,
    error: null,
  },
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.status = "idle";
      state.otpVerified = false;
      state.passwordReset = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle registration
      .addCase(registerUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
        state.otpVerified = false; // Reset OTP state after registration
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "Registration failed";
      })

      // Handle OTP verification
      .addCase(verifyOTP.pending, (state) => {
        state.status = "loading";
      })
      .addCase(verifyOTP.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.otpVerified = true;
      })
      .addCase(verifyOTP.rejected, (state, action) => {
        state.status = "failed";
        state.otpVerified = false;
        state.error = action.payload?.message || "OTP verification failed";
      })

      // Handle login
      .addCase(loginUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.token = action.payload.token;
        state.user = action.payload.user; // Assume backend returns user details
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "Login failed";
      })

      // Handle password reset request
      .addCase(requestResetPassword.pending, (state) => {
        state.status = "loading";
      })
      .addCase(requestResetPassword.fulfilled, (state) => {
        state.status = "succeeded";
        state.passwordReset = false; // Reset password state after request
      })
      .addCase(requestResetPassword.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "Password reset request failed";
      })

      // Handle OTP verification and password reset
      .addCase(verifyOTPAndResetPassword.pending, (state) => {
        state.status = "loading";
      })
      .addCase(verifyOTPAndResetPassword.fulfilled, (state) => {
        state.status = "succeeded";
        state.passwordReset = true; // Mark password as reset successfully
      })
      .addCase(verifyOTPAndResetPassword.rejected, (state, action) => {
        state.status = "failed";
        state.passwordReset = false;
        state.error = action.payload?.message || "Password reset failed";
      });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
