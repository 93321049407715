import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { registerUser, verifyOTP } from "../../redux/features/authSlice";
import {
  Button,
  TextField,
  CircularProgress,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { toast } from "sonner"; // For showing success or error messages
import { useNavigate } from "react-router-dom";

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    password: "",
    dept: "",
    qualification: "",
    email: "",
    phoneNumber: "",
  });
  const [otp, setOtp] = useState("");
  const [openOtpDialog, setOpenOtpDialog] = useState(false);

  const dispatch = useDispatch();
  const { status, error } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(registerUser(formData))
      .unwrap()
      .then(() => {
        toast.success("OTP sent to your email");
        setOpenOtpDialog(true); // Open OTP dialog after successful registration
      })
      .catch((error) => {
        toast.error(error.message || "Registration failed");
      });
  };

  const handleVerifyOTP = () => {
    dispatch(verifyOTP({ email: formData.email, otp }))
      .unwrap()
      .then(() => {
        toast.success("Registration successful!");
        setOpenOtpDialog(false);
        navigate("/login");
      })
      .catch((error) => {
        toast.error(error.message || "OTP verification failed");
      });
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: 600,
        mx: "auto",
        mt: 5,
        p: 4,
        borderRadius: 2,
        boxShadow: 3,
        backgroundColor: "#fff",
      }}
    >
      <Typography variant="h4" gutterBottom>
        Register
      </Typography>
      <TextField
        label="Name"
        variant="outlined"
        name="name"
        value={formData.name}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      <TextField
        label="Company"
        variant="outlined"
        name="company"
        value={formData.company}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      <TextField
        label="Password"
        variant="outlined"
        name="password"
        type="password"
        value={formData.password}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      <TextField
        label="Department"
        variant="outlined"
        name="dept"
        value={formData.dept}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      <TextField
        label="Qualification"
        variant="outlined"
        name="qualification"
        value={formData.qualification}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      <TextField
        label="Email"
        variant="outlined"
        name="email"
        type="email"
        value={formData.email}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      <TextField
        label="Phone Number"
        variant="outlined"
        name="phoneNumber"
        value={formData.phoneNumber}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 2 }}
        disabled={status === "loading"}
      >
        {status === "loading" ? <CircularProgress size={24} /> : "Register"}
      </Button>
      {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}

      {/* OTP Dialog */}
      <Dialog open={openOtpDialog} onClose={() => setOpenOtpDialog(false)}>
        <DialogTitle>Enter OTP</DialogTitle>
        <DialogContent>
          <TextField
            label="OTP"
            variant="outlined"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleVerifyOTP} color="primary" variant="contained">
            Verify OTP
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Register;
